import React, { useState } from "react";
import Papa from "papaparse";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./file.css";
import { checkboxClasses } from "@mui/material";

const MultiFileUploader = ({ handleMultiFileResults }) => {
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState("All"); // Add filter state

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    let allResults = [];

    files.forEach((file) => {
      Papa.parse(file, {
        header: false,
        complete: (result) => {
          const data = result.data; // Parse CSV data
          const processedResults = processFileData(data, file.name);
          allResults = [...allResults, ...processedResults]; // Accumulate results

          // Update results after all files are processed
          if (allResults.length) {
            setResults(allResults);
            handleMultiFileResults(allResults);
          }
        },
      });
    });
  };

  // Filter results based on selected filter
  const filteredResults = results.filter((result) => {
    const isMatch =
      Math.abs(
        parseFloat(
          (result.slsFlag === "S" || result.slsFlag === "R"
            ? result.grossSales
            : 0) -
            (result.slsFlag === "S" || result.slsFlag === "R"
              ? result.discountAmount
              : 0) -
            (result.slsFlag === "S" || result.slsFlag === "R"
              ? result.snrCitDisc
              : 0) -
            (result.slsFlag === "S" || result.slsFlag === "R"
              ? result.pwdDisc
              : 0)
        ).toFixed(2)
      ) ===
      Math.abs(
        parseFloat(
          [
            result.cashAmount,
            result.cardAmount,
            result.dCardAmount,
            result.ePayAmount,
            result.gcAmount,
            result.onlineSales,
            result.checkSales,
            result.foodPandaSales,
            result.grabSales,
          ]
            .filter((value) => value !== null && value !== undefined)
            .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        ).toFixed(2)
      );

    if (filter === "All") return true;
    if (filter === "Match") return isMatch;
    if (filter === "Mismatch") return !isMatch;
    return true;
  });

  const processFileData = (data, fileName) => {
    const processedResults = [];
    const transactions = {};
    let currentTransactionNo = null; // Declare currentTransactionNo here

    data.forEach((row) => {
      const index0 = row[0]?.trim();
      const index1 = row[1]?.trim();

      if (index0 === "TRANSACTION_NO") {
        currentTransactionNo = index1; // Set current transaction number
        if (!transactions[currentTransactionNo]) {
          transactions[currentTransactionNo] = {
            transactionNo: currentTransactionNo,
            slsFlag: null, // Initialize SLS_FLAG
            grossSales: 0,
            vatAmount: 0,
            vatableSales: 0,
            nonvatSales: 0,
            vatExemptSales: 0,
            vatExemptAmount: 0,
            localTax: 0,
            pwdDisc: 0,
            snrCitDisc: 0,
            emploDisc: 0,
            ayalaDisc: 0,
            storeDisc: 0,
            discountAmount: 0,
            refundAmount: 0,
            voidAmount: 0,
            servchargeAmount: 0,
            cashAmount: 0,
            cardAmount: 0,
            ePayAmount: 0,
            dCardAmount: 0,
            gcAmount: 0,
            onlineSales: 0,
            openSales: 0,
            gcExcess: 0,
            checkSales: 0,
            foodPandaSales: 0,
            grabSales: 0,
          };
        }
      } else if (index0 && index1) {
        const value = parseFloat(index1);

        if (currentTransactionNo && transactions[currentTransactionNo]) {
          // Check if currentTransactionNo is set
          switch (index0) {
            case "SLS_FLAG":
              // Only assign if it's not already assigned
              if (transactions[currentTransactionNo].slsFlag === null) {
                transactions[currentTransactionNo].slsFlag = index1;
                console.log(transactions[currentTransactionNo].slsFlag);
              }
              break;
            case "GROSS_SLS":
              transactions[currentTransactionNo].grossSales += value;
              break;
            case "VAT_AMNT":
              transactions[currentTransactionNo].vatAmount += value;
              break;
            case "VATABLE_SLS":
              transactions[currentTransactionNo].vatableSales += value;
              break;
            case "NONVAT_SLS":
              transactions[currentTransactionNo].nonvatSales += value;
              break;
            case "VATEXEMPT_SLS":
              transactions[currentTransactionNo].vatExemptSales += value;
              break;
            case "VATEXEMPT_AMNT":
              transactions[currentTransactionNo].vatExemptAmount += value;
              break;
            case "LOCAL_TAX":
              transactions[currentTransactionNo].localTax += value;
              break;
            case "PWD_DISC":
              transactions[currentTransactionNo].pwdDisc += value;
              break;
            case "SNRCIT_DISC":
              transactions[currentTransactionNo].snrCitDisc += value;
              break;
            case "EMPLO_DISC":
              transactions[currentTransactionNo].emploDisc += value;
              break;
            case "AYALA_DISC":
              transactions[currentTransactionNo].ayalaDisc += value;
              break;
            case "STORE_DISC":
              transactions[currentTransactionNo].storeDisc += value;
              break;
            case "OTHER_DISC":
              transactions[currentTransactionNo].discountAmount += value;
              break;
            case "REFUND_AMT":
              transactions[currentTransactionNo].refundAmount += value; // Adjusted logic to accumulate refunds
              break;
            case "SCHRGE_AMT":
              transactions[currentTransactionNo].servchargeAmount += value;
              break;
            case "CASH_SLS":
              transactions[currentTransactionNo].cashAmount += value;
              break;
            case "CHECK_SLS":
              transactions[currentTransactionNo].checkSales += value;
              break;
            case "FOODPANDA_SLS":
              transactions[currentTransactionNo].foodPandaSales += value;
              break;
            case "GRAB_SLS":
              transactions[currentTransactionNo].grabSales += value;
              break;
            case "GC_SLS":
              transactions[currentTransactionNo].gcAmount += value;
              break;
            case "MASTERCARD_SLS":
            case "VISA_SLS":
            case "AMEX_SLS":
            case "DINERS_SLS":
            case "JCB_SLS":
              transactions[currentTransactionNo].cardAmount += value;
              break;
            case "GCASH_SLS":
            case "PAYMAYA_SLS":
            case "ALIPAY_SLS":
            case "WECHAT_SLS":
              transactions[currentTransactionNo].ePayAmount += value;
              break;
            case "MASTERDEBIT_SLS":
            case "VISADEBIT_SLS":
              transactions[currentTransactionNo].dCardAmount += value;
              break;
            case "PAYPAL_SLS":
            case "ONLINE_SLS":
              transactions[currentTransactionNo].onlineSales += value;
              break;
            case "OPEN_SALES":
            case "OPEN_SALES_2":
            case "OPEN_SALES_3":
              transactions[currentTransactionNo].openSales += value;
              break;
            case "GC_EXCESS":
              transactions[currentTransactionNo].gcExcess += value;
              break;
            default:
              break;
          }
        }
      }
    });

    // Prepare the processed results
    Object.values(transactions).forEach((transaction) => {
      processedResults.push({
        fileName,
        transactionNo: transaction.transactionNo,
        slsFlag: transaction.slsFlag,
        grossSales: transaction.grossSales.toFixed(2),
        vatAmount: transaction.vatAmount.toFixed(2),
        vatableSales: transaction.vatableSales.toFixed(2),
        vatExemptSales: transaction.vatExemptSales.toFixed(2),
        vatExemptAmount: transaction.vatExemptAmount.toFixed(2),
        nonvatSales: transaction.nonvatSales.toFixed(2),
        snrCitDisc: transaction.snrCitDisc.toFixed(2),
        pwdDisc: transaction.pwdDisc.toFixed(2),
        servchargeAmount: transaction.servchargeAmount.toFixed(2),
        discountAmount: transaction.discountAmount.toFixed(2),
        cashAmount: transaction.cashAmount.toFixed(2),
        cardAmount: transaction.cardAmount.toFixed(2),
        ePayAmount: transaction.ePayAmount.toFixed(2),
        dCardAmount: transaction.dCardAmount.toFixed(2),
        gcAmount: transaction.gcAmount.toFixed(2),
        onlineSales: (
          transaction.onlineSales +
          transaction.openSales +
          transaction.gcExcess
        ).toFixed(2),
        refundAmount: transaction.refundAmount.toFixed(2),
        foodPandaSales: transaction.foodPandaSales.toFixed(2),
        grabSales: transaction.grabSales.toFixed(2),
        checkSales: transaction.checkSales.toFixed(2),
      });
    });

    return processedResults;
  };

  const calculateTotals = () => {
    return results.reduce(
      (totals, res) => {
        const grossSalesValue = parseFloat(res.grossSales) || 0;
        const cashAmountValue = parseFloat(res.cashAmount) || 0;
        const vatAmountValue = parseFloat(res.vatAmount) || 0;
        const vatableSalesValue = parseFloat(res.vatableSales) || 0;
        const nonvatSalesValue = parseFloat(res.nonvatSales) || 0;
        const slsFlag = res.slsFlag;

        // For 'R' flag, treat gross sales as zero (refund)
        if (slsFlag === "R") {
          totals.grossSales += 0; // Ignore this gross sale, essentially making it zero
          totals.cashAmount += 0; // Ignore this gross sale, essentially making it zero
          totals.nonvatSales += 0; // Ignore this gross sale, essentially making it zero
          totals.vatAmount += 0; // Ignore this gross sale, essentially making it zero
          totals.vatableSales += 0; // Ignore this gross sale, essentially making it zero
        } else {
          totals.grossSales += grossSalesValue; // Add normally for other SLS_FLAGs
          totals.cashAmount += cashAmountValue; // Add normally for other SLS_FLAGs
          totals.nonvatSales += nonvatSalesValue; // Add normally for other SLS_FLAGs
          totals.vatAmount += vatAmountValue; // Add normally for other SLS_FLAGs
          totals.vatableSales += vatableSalesValue; // Add normally for other SLS_FLAGs
        }

        // Add other fields normally
        totals.discountAmount += parseFloat(res.discountAmount) || 0;
        totals.cardAmount += parseFloat(res.cardAmount) || 0;
        totals.ePayAmount += parseFloat(res.ePayAmount) || 0;
        totals.dCardAmount += parseFloat(res.dCardAmount) || 0;
        totals.gcAmount += parseFloat(res.gcAmount) || 0;
        totals.onlineSales += parseFloat(res.onlineSales) || 0;
        totals.vatExemptSales += parseFloat(res.vatExemptSales) || 0;
        totals.vatExemptAmount += parseFloat(res.vatExemptAmount) || 0;
        totals.nonvatSales += parseFloat(res.nonvatSales) || 0;
        totals.refundAmount += parseFloat(res.refundAmount) || 0; // Add refund amount
        totals.snrCitDisc += parseFloat(res.snrCitDisc) || 0; // Add refund amount
        totals.pwdDisc += parseFloat(res.pwdDisc) || 0; // Add refund amount
        totals.servchargeAmount += parseFloat(res.servchargeAmount) || 0; // Add refund amount
        totals.checkSales += parseFloat(res.checkSales) || 0; // Add refund amount
        totals.grabSales += parseFloat(res.grabSales) || 0; // Add refund amount
        totals.foodPandaSales += parseFloat(res.foodPandaSales) || 0; // Add refund amount

        return totals;
      },
      {
        grossSales: 0,
        vatAmount: 0,
        vatableSales: 0,
        vatExemptSales: 0,
        vatExemptAmount: 0,
        nonvatSales: 0,
        snrCitDisc: 0,
        pwdDisc: 0,
        discountAmount: 0,
        cashAmount: 0,
        cardAmount: 0,
        ePayAmount: 0,
        dCardAmount: 0,
        gcAmount: 0,
        onlineSales: 0,
        refundAmount: 0, // Initialize refund amount
        servchargeAmount: 0, // Initialize refund amount
        checkSales: 0, // Initialize refund amount
        foodPandaSales: 0, // Initialize refund amount
        grabSales: 0, // Initialize refund amount
      }
    );
  };
  const totals = calculateTotals();

  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <h3>Hourly CSV</h3>
      <input
        type="file"
        accept=".csv"
        className="form-control"
        multiple
        onChange={handleFileUpload}
      />
      {results.length > 0 && (
        <div
          style={{ marginTop: "20px", maxHeight: "600px", overflowY: "auto" }}
        >
          <Table striped bordered hover>
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <tr style={{ marginTop: "-20px" }}>
                <th>File Name</th>
                <th>Trans #</th>
                {/* SLS_FLAG = S */}
                <th style={{ backgroundColor: "#8DB4E2" }}>Gross Sales (S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>VAT Amount (S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>VAT Sales (S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>
                  VAT EXEMPT SLS (S)
                </th>
                <th style={{ backgroundColor: "#8DB4E2" }}>
                  VAT EXEMPT AMNT (S)
                </th>
                <th style={{ backgroundColor: "#8DB4E2" }}>NON VAT (S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>SC AMOUNT(S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>PWD AMOUNT(S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>OTHER DISC (S)</th>
                <th style={{ backgroundColor: "#8DB4E2" }}>
                  REFUND AMOUNT (S)
                </th>
                {/* END OF SLS FLAG = S */}
                <th style={{ backgroundColor: "#FABF8F" }}>Gross Sales (R)</th>
                <th style={{ backgroundColor: "#FABF8F" }}>VAT Amount (R)</th>
                <th style={{ backgroundColor: "#FABF8F" }}>VAT Sales (R)</th>

                <th style={{ backgroundColor: "#FABF8F" }}>
                  VAT EXEMPT SLS (R)
                </th>
                <th style={{ backgroundColor: "#FABF8F" }}>
                  VAT EXEMPT AMNT (R)
                </th>
                <th style={{ backgroundColor: "#FABF8F" }}>NON VAT (R)</th>
                <th style={{ backgroundColor: "#FABF8F" }}>SC AMOUNT (R)</th>
                <th style={{ backgroundColor: "#FABF8F" }}>PWD AMOUNT (R)</th>
                <th style={{ backgroundColor: "#FABF8F" }}>OTHER DISC (R)</th>
                <th style={{ backgroundColor: "#FABF8F" }}>
                  REFUND AMOUNT (R)
                </th>
                {/* END OF R*/}
                <th style={{ backgroundColor: "#92D050" }}>
                  GROSS SALES (NET)
                </th>

                <th style={{ backgroundColor: "#92D050" }}>
                  Vatable Sales(NET)
                </th>
                <th style={{ backgroundColor: "#92D050" }}>VAT Amount(NET)</th>
                <th style={{ backgroundColor: "#92D050" }}>
                  VAT EXEMPT SLS (NET)
                </th>
                <th style={{ backgroundColor: "#92D050" }}>
                  VAT EXEMPT AMNT (NET)
                </th>
                <th style={{ backgroundColor: "#92D050" }}>NON VAT (NET)</th>
                <th style={{ backgroundColor: "#92D050" }}>SC AMOUNT (NET)</th>
                <th style={{ backgroundColor: "#92D050" }}>PWD AMOUNT (NET)</th>
                <th style={{ backgroundColor: "#92D050" }}>OTHER DISC (NET)</th>
                {/* END OF NET */}
                <th style={{ backgroundColor: "#B1A0C7" }}>Cash Amount</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>Card Amount</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>
                  Debit Card Amount
                </th>
                <th style={{ backgroundColor: "#B1A0C7" }}>E-Pay Amount</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>GC</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>Online Sales</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>Check Sales</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>FoodPanda</th>
                <th style={{ backgroundColor: "#B1A0C7" }}>GrabFood</th>
                <th style={{ backgroundColor: "#ff2" }}>Tender</th>
                <th style={{ backgroundColor: "#ff2" }}>NET</th>
                <th style={{ backgroundColor: "#ff2" }}>Discrepancy </th>
                <th style={{ backgroundColor: "#ff2" }}>Result</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={index}>
                  <td>{result.fileName}</td>
                  <td>{result.transactionNo}</td>
                  {/* S */}
                  <td>{result.slsFlag === "S" ? result.grossSales : ""}</td>
                  <td>{result.slsFlag === "S" ? result.vatAmount : ""}</td>
                  <td>{result.slsFlag === "S" ? result.vatableSales : ""}</td>
                  <td>{result.slsFlag === "S" ? result.vatExemptSales : ""}</td>
                  <td>
                    {result.slsFlag === "S" ? result.vatExemptAmount : ""}
                  </td>
                  <td>{result.slsFlag === "S" ? result.nonvatSales : ""}</td>
                  <td>{result.slsFlag === "S" ? result.snrCitDisc : ""}</td>
                  <td>{result.slsFlag === "S" ? result.pwdDisc : ""}</td>
                  <td>{result.slsFlag === "S" ? result.discountAmount : ""}</td>
                  <td>{result.slsFlag === "S" ? result.refundAmount : ""}</td>
                  {/* R */}
                  <td>
                    {result.slsFlag === "R" ? result.grossSales * -1 : ""}
                  </td>
                  <td>{result.slsFlag === "R" ? result.vatAmount * -1 : ""}</td>
                  <td>
                    {result.slsFlag === "R" ? result.vatableSales * -1 : ""}
                  </td>
                  <td>
                    {result.slsFlag === "R" ? result.vatExemptSales * -1 : ""}
                  </td>
                  <td>
                    {result.slsFlag === "R" ? result.vatExemptAmount * -1 : ""}
                  </td>
                  <td>
                    {result.slsFlag === "R" ? result.nonvatSales * -1 : ""}
                  </td>
                  <td>
                    {result.slsFlag === "R" ? result.snrCitDisc * -1 : ""}
                  </td>
                  <td>{result.slsFlag === "R" ? result.pwdDisc * -1 : ""}</td>
                  <td>
                    {result.slsFlag === "R" ? result.discountAmount * -1 : ""}
                  </td>
                  <td>
                    {result.slsFlag === "R" ? result.refundAmount * -1 : ""}
                  </td>
                  {/* NET */}
                  <td>
                    {result.slsFlag === "S"
                      ? result.grossSales
                      : result.slsFlag === "R"
                      ? result.grossSales * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.vatableSales
                      : result.slsFlag === "R"
                      ? result.vatableSales * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.vatAmount
                      : result.slsFlag === "R"
                      ? result.vatAmount * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.vatExemptSales
                      : result.slsFlag === "R"
                      ? result.vatExemptSales * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.vatExemptAmount
                      : result.slsFlag === "R"
                      ? result.vatExemptAmount * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.nonvatSales
                      : result.slsFlag === "R"
                      ? result.nonvatSales * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.snrCitDisc
                      : result.slsFlag === "R"
                      ? result.snrCitDisc * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.pwdDisc
                      : result.slsFlag === "R"
                      ? result.pwdDisc * -1
                      : ""}
                  </td>
                  <td>
                    {result.slsFlag === "S"
                      ? result.discountAmount
                      : result.slsFlag === "R"
                      ? result.discountAmount * -1
                      : ""}
                  </td>
                  {/* Sales Breakdown */}
                  <td>{result.cashAmount}</td>
                  <td>{result.cardAmount}</td>
                  <td>{result.dCardAmount}</td>
                  <td>{result.ePayAmount}</td>
                  <td>{result.gcAmount}</td>
                  <td>{result.onlineSales}</td>
                  <td>{result.checkSales}</td>
                  <td>{result.foodPandaSales}</td>
                  <td>{result.grabSales}</td>
                  {/* Tender */}
                  <td>
                    {Math.abs(
                      [
                        result.cashAmount,
                        result.cardAmount,
                        result.dCardAmount,
                        result.ePayAmount,
                        result.gcAmount,
                        result.onlineSales,
                        result.checkSales,
                        result.foodPandaSales,
                        result.grabSales,
                      ]
                        .filter(
                          (value) => value !== null && value !== undefined
                        )
                        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                    ).toFixed(2)}
                  </td>
                  {/* End Tender */}

                  {/* NET */}
                  <td>
                    {Math.abs(
                      (result.slsFlag === "S" || result.slsFlag === "R"
                        ? result.grossSales
                        : 0) -
                        (result.slsFlag === "S" || result.slsFlag === "R"
                          ? result.discountAmount
                          : 0) -
                        (result.slsFlag === "S" || result.slsFlag === "R"
                          ? result.snrCitDisc
                          : 0) -
                        (result.slsFlag === "S" || result.slsFlag === "R"
                          ? result.pwdDisc
                          : 0)
                    ).toFixed(2)}
                  </td>
                  {/* End Net */}

                  {/* Discrepancy */}
                  <td>
                    {Math.abs(
                      Math.abs(
                        [
                          result.cashAmount,
                          result.cardAmount,
                          result.dCardAmount,
                          result.ePayAmount,
                          result.gcAmount,
                          result.onlineSales,
                          result.checkSales,
                          result.foodPandaSales,
                          result.grabSales,
                        ]
                          .filter(
                            (value) => value !== null && value !== undefined
                          )
                          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                      ) -
                        Math.abs(
                          parseFloat(
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.grossSales
                              : 0) -
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.discountAmount
                                : 0) -
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.snrCitDisc
                                : 0) -
                              (result.slsFlag === "S" || result.slsFlag === "R"
                                ? result.pwdDisc
                                : 0)
                          )
                        )
                    ).toFixed(2)}
                  </td>
                  {/* End Discrepancy */}

                  {/* Match / Mismatch */}
                  <td
                    style={{
                      color: "#ffffff",
                      marginTop: "5px",
                      marginLeft: "5px",
                    }}
                    className={
                      Math.abs(
                        parseFloat(
                          (result.slsFlag === "S" || result.slsFlag === "R"
                            ? result.grossSales
                            : 0) -
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.discountAmount
                              : 0) -
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.snrCitDisc
                              : 0) -
                            (result.slsFlag === "S" || result.slsFlag === "R"
                              ? result.pwdDisc
                              : 0)
                        ).toFixed(2)
                      ) ===
                      Math.abs(
                        parseFloat(
                          [
                            result.cashAmount,
                            result.cardAmount,
                            result.dCardAmount,
                            result.ePayAmount,
                            result.gcAmount,
                            result.onlineSales,
                            result.checkSales,
                            result.grabSales,
                            result.foodPandaSales,
                          ]
                            .filter(
                              (value) => value !== null && value !== undefined
                            )
                            .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                        ).toFixed(2)
                      )
                        ? "badge bg-success"
                        : "badge bg-danger"
                    }
                  >
                    {Math.abs(
                      parseFloat(
                        (result.slsFlag === "S" || result.slsFlag === "R"
                          ? result.grossSales
                          : 0) -
                          (result.slsFlag === "S" || result.slsFlag === "R"
                            ? result.discountAmount
                            : 0) -
                          (result.slsFlag === "S" || result.slsFlag === "R"
                            ? result.snrCitDisc
                            : 0) -
                          (result.slsFlag === "S" || result.slsFlag === "R"
                            ? result.pwdDisc
                            : 0)
                      ).toFixed(2)
                    ) ===
                    Math.abs(
                      parseFloat(
                        [
                          result.cashAmount,
                          result.cardAmount,
                          result.dCardAmount,
                          result.ePayAmount,
                          result.gcAmount,
                          result.onlineSales,
                          result.checkSales,
                          result.foodPandaSales,
                          result.grabSales,
                        ]
                          .filter(
                            (value) => value !== null && value !== undefined
                          )
                          .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                      ).toFixed(2)
                    )
                      ? "Match"
                      : "Mismatch"}
                  </td>
                </tr>
              ))}
              {/* Totals */}
              <tr style={{ fontWeight: "bold" }}>
                <td colSpan={2}>
                  <strong>Totals</strong>
                </td>
                {/* S */}
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.grossSales || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.grossSales || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.vatAmount || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) => acc + parseFloat(curr.vatAmount || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.vatableSales || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatableSales || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.vatExemptSales || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatExemptSales || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.vatExemptAmount || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.vatExemptAmount || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.nonvatSales || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.nonvatSales || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.snrCitDisc || 0),
                        0
                      ) -
                      Math.abs(
                        results
                          .filter((result) => result.slsFlag === "R")
                          .reduce(
                            (acc, curr) =>
                              acc + parseFloat(curr.snrCitDisc || 0),
                            0
                          )
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.discountAmount || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.discountAmount || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                <td>
                  {Math.abs(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.refundAmount || 0),
                        0
                      ) -
                      results
                        .filter((result) => result.slsFlag === "R")
                        .reduce(
                          (acc, curr) =>
                            acc + parseFloat(curr.refundAmount || 0),
                          0
                        )
                  ).toLocaleString()}
                </td>
                {/* R */}
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) =>
                        acc + parseFloat(curr.grossSales * -1 || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.vatAmount * -1 || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) =>
                        acc + parseFloat(curr.vatableSales * -1 || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.vatExemptSales || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) =>
                        acc + parseFloat(curr.vatExemptAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.nonvatSales || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.snrCitDisc || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.discountAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .filter((result) => result.slsFlag === "R")
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.refundAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                {/* NET */}
                {/* <td colSpan={5} style={{ backgroundColor: "#000000" }}></td> */}
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.grossSales || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.grossSales || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.vatableSales || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.vatableSales || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.vatAmount || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.vatAmount || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.vatExemptSales || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.vatExemptSales || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.vatExemptAmount || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.vatExemptAmount || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.nonvatSales || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.nonvatSales || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.snrCitDisc || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.snrCitDisc || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) => acc + parseFloat(curr.pwdDisc || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {(
                    results
                      .filter((result) => result.slsFlag === "S")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.discountAmount || 0),
                        0
                      ) -
                    results
                      .filter((result) => result.slsFlag === "R")
                      .reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.discountAmount || 0),
                        0
                      )
                  ).toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.cashAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.cardAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.dCardAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.ePayAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.gcAmount || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.onlineSales || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.checkSales || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.foodPandaSales || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td>
                  {results
                    .reduce(
                      (acc, curr) => acc + parseFloat(curr.grabSales || 0),
                      0
                    )
                    .toLocaleString()}
                </td>
                <td colSpan={9}></td>
              </tr>
              <tr>
                <td colSpan={31}></td>
                <td
                  colSpan={9}
                  style={{
                    textAlign: "center",
                    fontSize: "1.2em",
                    backgroundColor: "#B1A0C7",
                  }}
                >
                  <strong>
                    {(
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.cashAmount || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.cardAmount || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.dCardAmount || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.ePayAmount || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.onlineSales || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.gcAmount || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.checkSales || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) =>
                          acc + parseFloat(curr.foodPandaSales || 0),
                        0
                      ) +
                      results.reduce(
                        (acc, curr) => acc + parseFloat(curr.grabSales || 0),
                        0
                      )
                    ).toLocaleString()}
                  </strong>
                </td>
                <td colSpan={9}></td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default MultiFileUploader;
